.Container {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  color: #555;
  cursor: pointer;
}

.MenuItem {
  padding: 5px 0;
}
