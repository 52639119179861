.Container {
  box-shadow: inset 0 0 1px;
  background-size: contain;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.MultipleCoins > div {
  margin-bottom: 0;
}
